import React from 'react'

import { BaseModal, BaseModalProps } from '../BaseModal'

export type SideModalProps = {
  direction?: 'left' | 'right'
} & BaseModalProps

/**
 * - [`Inter UI Documentation`](https://inter-ui.bancointer.com.br/?path=/story/modals-side-modal-since-v2-5-0--left)
 * - [`Figma Orange DS Documentation`](https://www.figma.com/file/FS12HnaqvY6B4K9mkTCSrr/DS-%2F-Components-%2F-Desktop?node-id=2539-7198&t=RabwOuj1svlNaWmz-0)
 *
 * **Code example**
 * ```tsx
 * import React, { useState, useCallback } from 'react'
 *
 * import { SideModal } from '@interco/inter-ui/components/Modal/SideModal'
 * import { Button } from '@interco/inter-ui/components/Button'
 *
 * export const MyPage = () => {
 *   const [visible, setVisible] = useState<boolean>(false)
 *
 *   const closeModal = useCallback(() => {
 *     setVisible(false)
 *   }, [setVisible])
 *
 *   const onModalConfirm = useCallback(() => {
 *     closeModal()
 *     // Other actions
 *   }, [closeModal])
 *
 *   return (
 *     <>
 *       <Button onClick={() => setVisible(true)}>Open Modal</Button>
 *
 *       <SideModal
 *         visible={visible}
 *         onClose={closeModal}
 *         onClickOutside={closeModal}
 *         title="Sample Title"
 *         direction="left"
 *       >
 *         Modal
 *       </SideModal>
 *     </>
 *   )
 * }
 * ```
 */
export const SideModal = ({
  visible,
  children,
  style,
  direction = 'right',
  modalContainerStyle,
  ...rest
}: SideModalProps) => (
  <BaseModal
    visible={visible}
    backdropStyle={{ justifyContent: direction === 'right' ? 'flex-end' : 'flex-start' }}
    modalContainerStyle={{
      height: '100vh',
      maxHeight: '100vh',
      maxWidth: '100%',
      borderRadius: 0,
      ...modalContainerStyle,
    }}
    style={style}
    {...rest}
  >
    {children}
  </BaseModal>
)
