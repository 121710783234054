import styled, { css } from 'styled-components'

import { StyledSignalProps } from './types'
import { signalColors } from './utils'

export const Container = styled.div<StyledSignalProps>`
  ${({ color, theme, intensity, type }: StyledSignalProps) => css`
    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 50%;

    width: 64px;
    height: 64px;

    ${((theme && intensity) || color) &&
    css`
      background-color: ${signalColors({ color, theme, intensity, type })};
    `}
  `}
`
