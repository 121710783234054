import React, { memo } from 'react'
import { withTranslation } from 'react-i18next'

import globoNasdaq from './assets/img/globo-nasdaq.png'

const SeloNasdaq = () => {
  return (
    <>
      <div className='d-flex d-block justify-content-start flex-column pt-lg-0'>
        <div className='selo-nasdaq col-auto col-lg-12 pl-0'>
          <div className='d-flex align-items-center justify-content-center'>
            <img className='ml-2' src={globoNasdaq} alt='Um globo' />
            <span className='fs-footer-14 ml-3'>
              <span className='fw-footer-700'>INTR
                <span className='nasdaq-text fw-footer-400 fs-footer-12 mx-2'>
                  |
                </span>
                Nasdaq
              </span>
              Listed
            </span>
          </div>
        </div>
      </div>
    </>
  )
}

export default withTranslation('Footer')(memo(SeloNasdaq))
