import React from 'react'
import { withTranslation } from 'react-i18next'

import SocialNetwork from './components/SocialNetwork'
import Store from './components/Store'
import Link from './components/Link'
import OrangeIcon from './components/OrangeDS'

// Hooks
import useDataLayer from '../../hooks/useDataLayer/dataLayerFooter'

import { IFooterLanguageProps } from './Footer.interface'
import SeloNasdaq from './components/SeloNasdaq/SeloNasdaq'
import PrivacyCertification from './components/PrivacyCertification/PrivacyCertification'

const FooterEN: React.FC<IFooterLanguageProps> = ({ t, theme, isPj }: IFooterLanguageProps): React.ReactElement => {
  const [ sendDatalayerFooterEvent ] = useDataLayer()

  return (
    <div id='footer'>
      <div className='row'>
        <div className='col-12 col-md-4 col-lg-3 pr-lg-0 d-none d-lg-block'>
          <div className='col-12 pl-lg-0 pr-lg-0 pb-4'>
            <SeloNasdaq />
          </div>
          <div className='col-12 pl-lg-0 pr-lg-0 pb-4'>
            <PrivacyCertification t={t} />
          </div>
          <div className='col-12 pl-lg-0'>
            <Store
              sendDatalayerFooterEvent={sendDatalayerFooterEvent}
              t={t}
              isPj={isPj}
            />
          </div>
          <div className='col-12 pl-lg-0 pt-lg-3 mt-lg-4'>
            <span className='title-font fw-footer-600 mb-4 mb-lg-3 fs-footer-16'>{t('social_network.follow_inter')}</span>
            <SocialNetwork
              title={t('social_network.follow_inter')}
              sendDatalayerFooterEvent={sendDatalayerFooterEvent}
              usedTheme={theme}
            />
          </div>
        </div>
        <div className='col-12 col-md-4 col-lg-3 mb-3'>
          <span className='title-font fw-footer-700 mb-3 fs-footer-16'>{t('quick_access.title')}</span>
          <ul className='list-unstyled'>
            <li>
              <a
                onClick={() => {
                  sendDatalayerFooterEvent({
                    section: t('quick_access.title'),
                    element_action: 'click button',
                    element_name: t('quick_access.investor_relations'),
                    redirect_url: 'https://ri.bancointer.com.br/en/',
                    c_page: window.location.href,
                  })
                }}
                href='https://ri.bancointer.com.br/en/'
                target='_blank'
                rel='noopener noreferrer'
                title={t('quick_access.investor_relations') + ''}
              >
                {t('quick_access.investor_relations')}
              </a>
            </li>
          </ul>
        </div>
        <div className='col-12 col-md-4 col-lg-3 footer-phones mb-3'>
          <span className='title-font fw-footer-700 mb-3 fs-footer-16'>{t('help_center.contact')}</span>
          <div className='d-flex d-md-block'>
            <ul className='list-unstyled list-phones col-6 col-md-12 pl-0 pr-4 pr-md-3'>
              <li>
                <span className='fs-footer-10 fs-footer-md-12'>
                  {t('help_center.capital_cities')}
                </span>
                <a
                  onClick={() => {
                    sendDatalayerFooterEvent({
                      section: t('help_center.contact'),
                      element_action: 'click button',
                      element_name: `${t('help_center.capital_cities')} - +55 3003 4070`,
                      redirect_url: 'tel:+5530034070',
                      c_page: window.location.href,
                    })
                  }}
                  href='tel:+5530034070'
                >+55 3003 4070
                </a>
              </li>
              <li>
                <span className='fs-footer-10 fs-footer-md-12'>
                  {t('help_center.other_cities')}
                </span>
                <a
                  onClick={() => {
                    sendDatalayerFooterEvent({
                      section: t('help_center.contact'),
                      element_action: 'click button',
                      element_name: `${t('help_center.other_cities')} - +55 0800 940 0007`,
                      redirect_url: 'tel:+5508009400007',
                      c_page: window.location.href,
                    })
                  }}
                  href='tel:+5508009400007'
                >+55 0800 940 0007
                </a>
              </li>
              <li>
                <span className='fs-footer-10 fs-footer-md-12'>
                  {t('help_center.sac')}
                </span>
                <a
                  onClick={() => {
                    sendDatalayerFooterEvent({
                      section: t('help_center.contact'),
                      element_action: 'click button',
                      element_name: `${t('help_center.sac')} - +55 0800 940 9999`,
                      redirect_url: 'tel:+5508009409999',
                      c_page: window.location.href,
                    })
                  }}
                  href='tel:+5508009409999'
                >+55 0800 940 9999
                </a>
              </li>
            </ul>
            <ul className='list-unstyled list-phones col-6 col-md-12 pl-0'>
              <li>
                <span className='fs-footer-10 fs-footer-md-12'>
                  {t('help_center.impaired')}
                </span>
                <a
                  onClick={() => {
                    sendDatalayerFooterEvent({
                      section: t('help_center.contact'),
                      element_action: 'click button',
                      element_name: `${t('help_center.impaired')} - +55 0800 979 7099`,
                      redirect_url: 'tel:+5508009797099',
                      c_page: window.location.href,
                    })
                  }}
                  href='tel:+5508009797099'
                >+55 0800 979 7099
                </a>
              </li>
              <li>
                <span className='fs-footer-10 fs-footer-md-12'>
                  {t('help_center.ombudsman')}
                </span>
                <a
                  onClick={() => {
                    sendDatalayerFooterEvent({
                      section: t('help_center.contact'),
                      element_action: 'click button',
                      element_name: `${t('help_center.ombudsman')} - +55 0800 940 7772`,
                      redirect_url: 'tel:+5508009407772',
                      c_page: window.location.href,
                    })
                  }}
                  href='tel:+5508009407772'
                >+55 0800 940 7772
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className='col-12 col-md-4 col-lg-3'>
          <span className='title-font fw-footer-700 mb-3 fs-footer-16'>{t('help_center.products.title')}</span>
          <ul className='list-unstyled'>
            <li>
              <Link
                onClick={() => {
                  sendDatalayerFooterEvent({
                    section: t('help_center.products.title'),
                    element_action: 'click button',
                    element_name: t('help_center.products.digital_account'),
                    redirect_url: `${window.location.origin}/en/digital-account/`,
                    c_page: window.location.href,
                  })
                }}
                to='/en/digital-account/'
                title={t('help_center.products.digital_account')}
              >
                {t('help_center.products.digital_account')}
              </Link>
            </li>
          </ul>
        </div>
        <div className='col-12 d-lg-none pb-4 pt-2'>
          <SeloNasdaq />
        </div>
        <div className='col-12 col-md-6 d-lg-none pt-2'>
          <PrivacyCertification t={t} />
        </div>
        <div className='col-12 col-md-6 d-lg-none'>
          <Store
            sendDatalayerFooterEvent={sendDatalayerFooterEvent}
            t={t}
          />
        </div>
        <div className='col-12 col-md-6 d-lg-none mt-md-3'>
          <span className='title-font fw-footer-600 mb-4 mb-lg-3 fs-footer-16'>{t('social_network.follow_inter')}</span>
          <SocialNetwork
            title={t('social_network.follow_inter')}
            sendDatalayerFooterEvent={sendDatalayerFooterEvent}
            usedTheme={theme}
          />
        </div>
      </div>
      <div className='row sub-footer'>
        <div className='col-12 px-3 text-left'>
          <ul className='d-flex list-inline'>
            <li className='text-left fs-footer-10 pr-3'>
              <Link
                onClick={() => {
                  sendDatalayerFooterEvent({
                    section: '',
                    element_action: 'click button',
                    element_name: t('privacy_policy'),
                    redirect_url: `${window.location.origin}/en/privacy-policy/`,
                    c_page: window.location.href,
                  })
                }}
                to='/en/privacy-policy/' title={t('privacy_policy')}
              >{t('privacy_policy')}
              </Link>
            </li>
            <li className='text-left fs-footer-10 pr-3'>
              <Link
                onClick={() => {
                  sendDatalayerFooterEvent({
                    section: '',
                    element_action: 'click button',
                    element_name: t('data_privacy'),
                    redirect_url: `${window.location.origin}/en/data-privacy/`,
                    c_page: window.location.href,
                  })
                }}
                to='/en/data-privacy/' title={t('data_privacy')}
              >{t('data_privacy')}
              </Link>
            </li>
            <li className='text-left fs-footer-10 pr-3'>
              <Link
                onClick={() => {
                  sendDatalayerFooterEvent({
                    section: '',
                    element_action: 'click button',
                    element_name: t('security'),
                    redirect_url: `${window.location.origin}/en/security/`,
                    c_page: window.location.href,
                  })
                }}
                to='/en/security/' title={t('security')}
              >{t('security')}
              </Link>
            </li>
            <li className='text-left fs-footer-10 pr-3'>
              <Link
                onClick={() => {
                  sendDatalayerFooterEvent({
                    section: '',
                    element_action: 'click button',
                    element_name: t('security_policy'),
                    redirect_url: `${window.location.origin}/en/cybersecurity-policy/`,
                    c_page: window.location.href,
                  })
                }}
                to='/en/cybersecurity-policy/' title={t('security_policy')}
              >{t('security_policy')}
              </Link>
            </li>
            <li className='text-left fs-footer-10 pr-3'>
              <Link
                onClick={() => {
                  sendDatalayerFooterEvent({
                    section: '',
                    element_action: 'click button',
                    element_name: t('security_policy_for_suppliers'),
                    redirect_url: `${window.location.origin}/en/security-policy-for-suppliers/`,
                    c_page: window.location.href,
                  })
                }}
                to='/en/security-policy-for-suppliers/' title={t('security_policy_for_suppliers')}
              >{t('security_policy_for_suppliers')}
              </Link>
            </li>
          </ul>
          <address>
            <div className='d-flex align-items-center mb-3'>
              &copy; <span className='fs-footer-10 ml-1 text-left' dangerouslySetInnerHTML={{ __html: t('cnpj') }} />
            </div>
            <div className='d-xl-flex'>
              <div className='d-flex mr-xl-3 mb-2 mb-xl-0'>
                <a
                  onClick={() => {
                    sendDatalayerFooterEvent({
                      section: '',
                      element_action: 'click button',
                      element_name: t('address_bh'),
                      redirect_url: 'https://goo.gl/maps/6ELbHe2AsaN6VzsE7',
                      c_page: window.location.href,
                    })
                  }}
                  href='https://goo.gl/maps/6ELbHe2AsaN6VzsE7' className='address d-flex' target='_blank' rel='noopener noreferrer'
                >
                  <OrangeIcon size='SM' icon='location' />
                  <span className='col fs-footer-10 pl-1 text-left' dangerouslySetInnerHTML={{ __html: t('address_bh') }} />
                </a>
              </div>
              <div className='d-flex mr-xl-3 mb-2 mb-xl-0'>
                <a
                  onClick={() => {
                    sendDatalayerFooterEvent({
                      section: '',
                      element_action: 'click button',
                      element_name: t('address_sp'),
                      redirect_url: 'https://goo.gl/maps/dhFZGQQuaAsU8fuy9',
                      c_page: window.location.href,
                    })
                  }}
                  href='https://goo.gl/maps/dhFZGQQuaAsU8fuy9' className='address d-flex' target='_blank' rel='noopener noreferrer'
                >
                  <OrangeIcon size='SM' icon='location' />
                  <span className='col fs-footer-10 pl-1 text-left' dangerouslySetInnerHTML={{ __html: t('address_sp') }} />
                </a>
              </div>
            </div>
          </address>
        </div>
      </div>
    </div>
  )
}

export default withTranslation('Footer')(FooterEN)
