import styled from 'styled-components'

import { interGridBreakpoints, orange, white, grayscale } from './styles/utils/variables'

type BgFooter = {
  theme: string;
}

type Colors = {
  background: string;
  color: {
    default: string;
    address: string;
    disclamer: string;
    contactType: string;
    icon: string;
  };
  border: string;
}

type Theme = {
  [params: string]: Colors;
  light: Colors;
  dark: Colors;
}

export const theme: Theme = {
  light: {
    background: white,
    color: {
      default: grayscale['500'],
      address: grayscale['500'],
      disclamer: grayscale['400'],
      contactType: grayscale['400'],
      icon: grayscale['400'],
    },
    border: grayscale['200'],
  },
  dark: {
    background: grayscale['500'],
    color: {
      default: white,
      address: white,
      disclamer: grayscale['200'],
      contactType: grayscale['100'],
      icon: grayscale['300'],
    },
    border: grayscale['400'],
  },
}

export const Footer = styled.footer<BgFooter>`
  background: ${(props: BgFooter) => theme[props.theme].background};
  border-top: 1px solid ${(props: BgFooter) => theme[props.theme].border};
  color: ${(props: BgFooter) => theme[props.theme].color.default};
  font-size: 12px;
  font-family: 'Inter', Arial, Helvetica, sans-serif;
  padding: 32px 0;
  text-align: left;

  @media (min-width: ${interGridBreakpoints.md}) {
    padding: 40px 0;
  }  

  a {
    &:not([class]) {
      color: currentColor;
    }
  }

  ul {
    li {
      line-height: 16px;
      margin-bottom: 24px;

      a {
        
        &:hover,
        &:focus {
          color: ${orange.extra};
          text-decoration: none;
        }             
      }     

      &.list-inline-item {
        margin-bottom: 0;       
      }
    }

    &.list-phones {
      
      .disclamer-md {
        color: ${(props: BgFooter) => theme[props.theme].color.contactType};
      }

      li {
        line-height: 13px;
        margin-bottom: 20px;
        
        @media (min-width: ${interGridBreakpoints.md}) {
          line-height: 15px;
        } 

        span {
          display: block;
          color: ${(props: BgFooter) => theme[props.theme].color.contactType};
        }

        a {
          font-weight: 700;
            @media (min-width: ${interGridBreakpoints.md}) {
            font-size: 14px;

            svg {
              &:hover {
                fill: ${orange.extra};
              }
            }
          }
        }
        &:last-child {
          line-height: 15px;
        }
      }
    }
  }
  
  .social-app {
    svg {
      max-width: 100%;    
      fill: ${(props: BgFooter) => theme[props.theme].color.default} !important;
    }
  }

  .disclamer {
    p {
      color: ${(props: BgFooter) => theme[props.theme].color.disclamer};
      line-height: 1.67;
    }
  }

  .sub-footer {
    @media (min-width: ${interGridBreakpoints.md}) {
      border-top: solid 1px ${(props: BgFooter) => theme[props.theme].border};
    }  

    .address {
      &:hover {
        svg {
          stroke: ${orange.extra};
        }
      }
      
      svg {
        max-width: 100%;
        fill: transparent;
        stroke: ${(props: BgFooter) => theme[props.theme].color.icon};
        
        &:hover {
          stroke: ${orange.extra};
        }   
      } 
    }

    font-size: 12px;
    margin-top: 30px;

    @media (min-width: ${interGridBreakpoints.lg}) {
      padding-top: 6px;
    }
    
    ul {
      margin-bottom: 14px;
      padding-top: 28px;
      
      border-top: solid 1px ${(props: BgFooter) => theme[props.theme].border};

      @media (min-width: ${interGridBreakpoints.md}) {
        border: none;
      }

      .list-inline-item {
        margin-bottom: 8px;

        @media (min-width: ${interGridBreakpoints.md}) {
          text-align: left;
        }

        &:not(:last-child) {
          margin-right: 16px;
        }   
        a {
          font-size: 10px;
        }
      }
    }

    address {
      color: ${(props: BgFooter) => theme[props.theme].color.address};
      line-height: 14px;
      margin-bottom: 0;
      
      a {
        color: ${(props: BgFooter) => theme[props.theme].color.address};
        &:hover {
          color: ${orange.extra};
          text-decoration: none;
          svg {
            stroke: ${orange.extra};
          }
        }
      }
    }
  }

  svg {
    max-width: 100%;
    fill: ${(props: BgFooter) => theme[props.theme].color.default};
    margin-left: -1px;
  }

  .title-font {
    font-family: 'Sora';
    line-height: 20px;
    display: block;
  }

  .selo-nasdaq {
    width: 228px;
    border: solid 2px ${orange.base};
    border-radius: 50px;
    padding: 15px;

    .nasdaq-text {
      color: ${orange.extra};
    }
  }

  .bureau-veritas {
    @media (min-width: ${interGridBreakpoints.md}) {
      margin-bottom: -2px;
    }
    svg {
      padding-left: 2px;
      path {
        fill: ${(props: BgFooter) => theme[props.theme].color.default};
      }
    }
  }
`

export const FooterContainer = styled.div`
  margin: 0 auto;
  width: 100%;
  padding-right: 24px;
  padding-left: 24px;

  @media (min-width: ${interGridBreakpoints.md}) {
    max-width: 720px;
    padding-right: 12px;
    padding-left: 12px;
  }

  @media (min-width: ${interGridBreakpoints.lg}) {
    max-width: 960px;
  }
  @media (min-width: ${interGridBreakpoints.xl}) {
    max-width: 1152px;
  }

  @media (min-width: ${interGridBreakpoints.xxl}) {
    max-width: 1440px;
  }
`
