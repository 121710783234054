import React from 'react'

import * as S from './styles'
import { signalColors } from './utils'
import { SignalProps } from './types'

export type { SignalProps }

/**
 * - [`Inter UI Documentation`](https://inter-ui.bancointer.com.br/?path=/story/pages-utils-signal-since-v-3-9-0--primary)
 * - [`Figma Orange DS Documentation`](https://www.figma.com/file/7QVC2u96bJmyW8qQ8mBNb3/DS-%2F-Components-%2F-Global?node-id=8555-3457&t=iAihbqXvNckoHAof-0)
 *
 * **Code example**
 * ```tsx
 * import React from 'react'
 *
 * import { Signal } from '@interco/inter-ui/components/Signal'
 * import PigIcon from '@interco/icons/orangeds/MD/pig'
 *
 * export const MyPage = () => {
 *   return <Signal icon={PigIcon} color="#039BE5", iconColor="white", />
 * }
 * ```
 */
export const Signal = ({
  icon: Image,
  theme,
  intensity,
  color,
  iconColor,
  ...attrs
}: SignalProps) => (
  <S.Container {...attrs} color={color} theme={theme} intensity={intensity} type="background">
    <Image
      color={signalColors({ iconColor, theme, intensity, type: 'icon' })}
      height={32}
      width={32}
    />
  </S.Container>
)
