import React from 'react'
import { withTranslation } from 'react-i18next'

import SocialNetwork from './components/SocialNetwork'
import Store from './components/Store'
import Link from './components/Link'
import OrangeIcon from './components/OrangeDS'
import SeloNasdaq from './components/SeloNasdaq/SeloNasdaq'
import PrivacyCertification from './components/PrivacyCertification/PrivacyCertification'

// Hooks
import useDataLayer from '../../hooks/useDataLayer/dataLayerFooter'

import { IFooterLanguageProps } from './Footer.interface'

const FooterPT: React.FC<IFooterLanguageProps> = ({ t, theme, isPj }: IFooterLanguageProps): React.ReactElement => {
  const [ sendDatalayerFooterEvent ] = useDataLayer()

  return (
    <div id='footer'>
      <div className='row'>
        <div className='col-12 col-md-4 col-lg-3 d-none d-lg-block pr-lg-0 social-app'>
          <div className='col-12 pl-lg-0 pr-lg-0 pb-4 d-flex flex-column justify-content-end'>
            <SeloNasdaq />
          </div>
          <div className='col-12 pl-lg-0 pr-lg-0 pb-4'>
            <PrivacyCertification t={t} />
          </div>
          <div className='col-12 pl-lg-0'>
            <Store
              sendDatalayerFooterEvent={sendDatalayerFooterEvent}
              isPj={isPj}
              t={t}
            />
          </div>
          <div className='col-12 pl-lg-0 pt-lg-3 mt-lg-4'>
            <span className='title-font fw-footer-600 mb-4 mb-lg-3 fs-footer-16'>{t('social_network.follow_inter')}</span>
            <SocialNetwork
              title={t('social_network.follow_inter')}
              usedTheme={theme}
              sendDatalayerFooterEvent={sendDatalayerFooterEvent}
            />
          </div>
        </div>
        <div className='col-12 col-md-4 col-lg-3'>
          <span className='title-font fw-footer-700 mb-3 fs-footer-16'>{t('help_center.products.title')}</span>
          <div className='d-flex d-md-block'>
            <ul className='list-unstyled col-6 col-md-12 pl-0 pr-5 pr-md-3'>
              <li data-footer='conta-digital-pessoa-fisica'>
                <Link
                  onClick={() => {
                    sendDatalayerFooterEvent({
                      section: t('help_center.products.title'),
                      element_action: 'click button',
                      element_name: t('help_center.products.digital_account_pf'),
                      redirect_url: `${window.location.origin}/pra-voce/conta-digital/pessoa-fisica/`,
                      c_page: window.location.href,
                    })
                  }}
                  to='/pra-voce/conta-digital/pessoa-fisica/'
                  title={t('help_center.products.digital_account_pf')}
                >
                  {t('help_center.products.digital_account_pf')}
                </Link>
              </li>
              <li data-footer='cartao-de-credito'>
                <Link
                  onClick={() => {
                    sendDatalayerFooterEvent({
                      section: t('help_center.products.title'),
                      element_action: 'click button',
                      element_name: t('help_center.products.credit_card'),
                      redirect_url: `${window.location.origin}/pra-voce/cartoes/`,
                      c_page: window.location.href,
                    })
                  }}
                  to='/pra-voce/cartoes/' title={t('help_center.products.credit_card')}
                >
                  {t('help_center.products.credit_card')}
                </Link>
              </li>
              <li data-footer='conta-digital-pessoa-juridica'>
                <Link
                  onClick={() => {
                    sendDatalayerFooterEvent({
                      section: t('help_center.products.title'),
                      element_action: 'click button',
                      element_name: t('help_center.products.digital_account_pj'),
                      redirect_url: `${window.location.origin}/empresas/conta-digital/pessoa-juridica/`,
                      c_page: window.location.href,
                    })
                  }}
                  to='/empresas/conta-digital/pessoa-juridica/'
                  title={t('help_center.products.digital_account_pj')}
                >
                  {t('help_center.products.digital_account_pj')}
                </Link>
              </li>
              <li data-footer='conta-digital-mei'>
                <Link
                  onClick={() => {
                    sendDatalayerFooterEvent({
                      section: t('help_center.products.title'),
                      element_action: 'click button',
                      element_name: t('help_center.products.digital_account_mei'),
                      redirect_url: `${window.location.origin}/empresas/conta-digital/mei/`,
                      c_page: window.location.href,
                    })
                  }}
                  to='/empresas/conta-digital/mei/'
                  title={t('help_center.products.digital_account_mei')}
                >
                  {t('help_center.products.digital_account_mei')}
                </Link>
              </li>
              <li data-footer='emprestimos'>
                <Link
                  onClick={() => {
                    sendDatalayerFooterEvent({
                      section: t('help_center.products.title'),
                      element_action: 'click button',
                      element_name: t('help_center.products.credit'),
                      redirect_url: `${window.location.origin}/credito-pessoal-e-credito-imobiliario/`,
                      c_page: window.location.href,
                    })
                  }}
                  to='/credito-pessoal-e-credito-imobiliario/'
                  title={t('help_center.products.credit')}
                >
                  {t('help_center.products.credit')}
                </Link>
              </li>
            </ul>
            <ul className='list-unstyled col-6 col-md-12 pl-0'>
              <li data-footer='inter-investimentos'>
                <Link
                  onClick={() => {
                    sendDatalayerFooterEvent({
                      section: t('help_center.products.title'),
                      element_action: 'click button',
                      element_name: t('help_center.products.investiments'),
                      redirect_url: `${window.location.origin}/pra-voce/investimentos/`,
                      c_page: window.location.href,
                    })
                  }}
                  to='/pra-voce/investimentos/'
                  title={t('help_center.products.investiments')}
                >
                  {t('help_center.products.investiments')}
                </Link>
              </li>
              <li data-footer='inter-shop'>
                <a
                  onClick={() => {
                    sendDatalayerFooterEvent({
                      section: t('help_center.products.title'),
                      element_action: 'click button',
                      element_name: t('help_center.products.inter_shop'),
                      redirect_url: 'https://intershop.bancointer.com.br',
                      c_page: window.location.href,
                    })
                  }}
                  href='https://intershop.bancointer.com.br'
                  title={t('help_center.products.inter_shop')}
                >
                  {t('help_center.products.inter_shop')}
                </a>
              </li>
              <li data-footer='seguros'>
                <Link
                  onClick={() => {
                    sendDatalayerFooterEvent({
                      section: t('help_center.products.title'),
                      element_action: 'click button',
                      element_name: t('help_center.products.ensurances'),
                      redirect_url: `${window.location.origin}/pra-voce/seguros/`,
                      c_page: window.location.href,
                    })
                  }}
                  to='/pra-voce/seguros/'
                  title={t('help_center.products.ensurances')}
                >
                  {t('help_center.products.ensurances')}
                </Link>
              </li>
              <li data-footer='super-app'>
                <Link
                  onClick={() => {
                    sendDatalayerFooterEvent({
                      section: t('help_center.products.title'),
                      element_action: 'click button',
                      element_name: t('help_center.products.super_app'),
                      redirect_url: `${window.location.origin}/super-app-inter/`,
                      c_page: window.location.href,
                    })
                  }}
                  to='/super-app-inter/'
                  title={t('help_center.products.super_app')}
                >{t('help_center.products.super_app')}
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div className='col-12 col-md-4 col-lg-3'>
          <span className='title-font fw-footer-700 mb-3 fs-footer-16'>{t('quick_access.title')}</span>
          <div className='d-flex d-md-block'>
            <ul className='list-unstyled col-6 col-md-12 pl-0 pr-4 pr-md-3'>
              <li data-footer='central-de-ajuda'>
                <a
                  onClick={() => {
                    sendDatalayerFooterEvent({
                      section: t('quick_access.title'),
                      element_action: 'click button',
                      element_name: t('help_center.title'),
                      redirect_url: 'https://ajuda.inter.co/',
                      c_page: window.location.href,
                    })
                  }}
                  href='https://ajuda.inter.co/'
                  target='_blank'
                  rel='noopener noreferrer'
                  title={t('help_center.title') + ''}
                >
                  {t('help_center.title')}
                </a>
              </li>
              <li data-footer='canais-de-atendimento'>
                <Link
                  onClick={() => {
                    sendDatalayerFooterEvent({
                      section: t('quick_access.title'),
                      element_action: 'click button',
                      element_name: t('help_center.call_center'),
                      redirect_url: `${window.location.origin}/canais-de-atendimento/`,
                      c_page: window.location.href,
                    })
                  }}
                  to='/canais-de-atendimento/'
                  title={t('help_center.call_center')}
                >{t('help_center.call_center')}
                </Link>
              </li>
              <li data-footer='ouvidoria'>
                <Link
                  onClick={() => {
                    sendDatalayerFooterEvent({
                      section: t('quick_access.title'),
                      element_action: 'click button',
                      element_name: t('help_center.ombudsman'),
                      redirect_url: `${window.location.origin}/ouvidoria/`,
                      c_page: window.location.href,
                    })
                  }}
                  to='/ouvidoria/'
                  title={t('help_center.ombudsman')}
                >{t('help_center.ombudsman')}
                </Link>
              </li>
            </ul>
            <ul className='list-unstyled col-6 col-md-12 pl-0'>
              <li data-footer='segunda-via-do-boleto'>
                <Link
                  onClick={() => {
                    sendDatalayerFooterEvent({
                      section: t('quick_access.title'),
                      element_action: 'click button',
                      element_name: t('quick_access.second_billet'),
                      redirect_url: `${window.location.origin}/segunda-via-boleto/`,
                      c_page: window.location.href,
                    })
                  }}
                  to='/segunda-via-boleto/'
                  title={t('quick_access.second_billet')}
                >
                  {t('quick_access.second_billet')}
                </Link>
              </li>
              <li data-footer='saldo-devedor'>
                <Link
                  onClick={() => {
                    sendDatalayerFooterEvent({
                      section: t('quick_access.title'),
                      element_action: 'click button',
                      element_name: t('quick_access.balance'),
                      redirect_url: `${window.location.origin}/saldo-devedor/`,
                      c_page: window.location.href,
                    })
                  }}
                  to='/saldo-devedor/'
                  title={t('quick_access.balance')}
                >{t('quick_access.balance')}
                </Link>
              </li>
              <li data-footer='negocie-sua-divida'>
                <Link
                  onClick={() => {
                    sendDatalayerFooterEvent({
                      section: t('quick_access.title'),
                      element_action: 'click button',
                      element_name: t('quick_access.negotiate'),
                      redirect_url: `${window.location.origin}/negocie/`,
                      c_page: window.location.href,
                    })
                  }}
                  to='/negocie/'
                  title={t('quick_access.negotiate')}
                >{t('quick_access.negotiate')}
                </Link>
              </li>
              <li data-footer='blog'>
                <a
                  onClick={() => {
                    sendDatalayerFooterEvent({
                      section: t('quick_access.title'),
                      element_action: 'click button',
                      element_name: t('quick_access.blog'),
                      redirect_url: 'https://blog.inter.co/',
                      c_page: window.location.href,
                    })
                  }}
                  href='https://blog.inter.co/'
                  target='_blank'
                  rel='noopener noreferrer'
                  title={t('quick_access.blog')}
                >{t('quick_access.blog')}
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className='col-12 col-md-4 col-lg-3 footer-phones'>
          <span className='title-font fw-footer-700 mb-3 fs-footer-16'>{t('help_center.contact')}</span>
          <div className='d-flex d-md-block'>
            <ul className='list-unstyled list-phones col-6 col-md-12 pl-0 pr-4 pr-md-3 mb-2'>
              <li>
                <span className='fs-footer-10 fs-footer-md-12'>
                  {t('help_center.capital_cities')}
                </span>
                <a
                  onClick={() => {
                    sendDatalayerFooterEvent({
                      section: t('help_center.contact'),
                      element_action: 'click button',
                      element_name: `${t('help_center.capital_cities')} - +55 3003 4070`,
                      redirect_url: 'tel:30034070',
                      c_page: window.location.href,
                    })
                  }}
                  href='tel:30034070'
                >3003 4070
                </a>
              </li>
              <li>
                <span className='fs-footer-10 fs-footer-md-12'>
                  {t('help_center.other_cities')}
                </span>
                <a
                  onClick={() => {
                    sendDatalayerFooterEvent({
                      section: t('help_center.contact'),
                      element_action: 'click button',
                      element_name: `${t('help_center.other_cities')} - +55 0800 940 0007`,
                      redirect_url: 'tel:08009400007',
                      c_page: window.location.href,
                    })
                  }}
                  href='tel:08009400007'
                >0800 940 0007
                </a>
              </li>
              <li>
                <span className='fs-footer-10 fs-footer-md-12'>
                  {t('help_center.sac')}
                </span>
                <a
                  onClick={() => {
                    sendDatalayerFooterEvent({
                      section: t('help_center.contact'),
                      element_action: 'click button',
                      element_name: `${t('help_center.sac')} - +55 0800 940 9999`,
                      redirect_url: 'tel:08009409999',
                      c_page: window.location.href,
                    })
                  }}
                  href='tel:08009409999'
                >0800 940 9999
                </a>
              </li>
            </ul>
            <ul className='list-unstyled list-phones col-6 col-md-12 pl-0'>
              <li>
                <span className='fs-footer-10 fs-footer-md-12'>
                  {t('help_center.impaired')}
                </span>
                <a
                  onClick={() => {
                    sendDatalayerFooterEvent({
                      section: t('help_center.contact'),
                      element_action: 'click button',
                      element_name: `${t('help_center.impaired')} - +55 0800 979 7099`,
                      redirect_url: 'tel:08009797099',
                      c_page: window.location.href,
                    })
                  }}
                  href='tel:08009797099'
                >0800 979 7099
                </a>
              </li>
              <li>
                <span className='fs-footer-10 fs-footer-md-12'>
                  {t('help_center.ombudsman')}
                </span>
                <a
                  onClick={() => {
                    sendDatalayerFooterEvent({
                      section: t('help_center.contact'),
                      element_action: 'click button',
                      element_name: `${t('help_center.ombudsman')} - +55 0800 940 7772`,
                      redirect_url: 'tel:08009407772',
                      c_page: window.location.href,
                    })
                  }}
                  href='tel:08009407772'
                >0800 940 7772
                </a>
              </li>
              <li className='fs-footer-12 d-none d-md-block disclamer-md' dangerouslySetInnerHTML={{ __html: t('disclamer') }} />
            </ul>
          </div>
        </div>
        <div className='col-12 pb-4 d-md-none disclamer text-left'>
          <p className='fs-footer-12 mb-0' dangerouslySetInnerHTML={{ __html: t('disclamer') }} />
        </div>
        <div className='col-12 col-md-6 d-lg-none my-3 pb-md-2 mb-md-4'>
          <SeloNasdaq />
        </div>
        <div className='col-12 col-md-6 d-lg-none pt-2 my-3 mb-md-4 justify-content-end'>
          <PrivacyCertification t={t} />
        </div>
        <div className='col-12 col-md-6 d-lg-none'>
          <Store
            sendDatalayerFooterEvent={sendDatalayerFooterEvent}
            t={t}
          />
        </div>
        <div className='col-12 col-md-6 d-lg-none mt-md-3'>
          <span className='title-font fw-footer-600 mb-4 mb-lg-3 fs-footer-16'>{t('social_network.follow_inter')}</span>
          <SocialNetwork
            title={t('social_network.follow_inter')}
            sendDatalayerFooterEvent={sendDatalayerFooterEvent}
            usedTheme={theme}
          />
        </div>
      </div>
      <div className='row sub-footer'>
        <div className='col-12 text-left'>
          <ul className='list-inline'>
            <li className='list-inline-item' data-footer='trabalhe-com-a-gente'>
              <a
                onClick={() => {
                  sendDatalayerFooterEvent({
                    section: '',
                    element_action: 'click button',
                    element_name: t('work_with_us'),
                    redirect_url: 'https://carreiras.inter.co',
                    c_page: window.location.href,
                  })
                }}
                href='https://carreiras.inter.co'
                title='Trabalhe com a gente'
              >
                {t('work_with_us')}
              </a>
            </li>
            <li className='list-inline-item' data-footer='cotacao-dolar'>
              <Link
                onClick={() => {
                  sendDatalayerFooterEvent({
                    section: '',
                    element_action: 'click button',
                    element_name: t('quick_access.dollar_quotation'),
                    redirect_url: `${window.location.origin}/pra-voce/cartoes/cotacao-dolar/`,
                    c_page: window.location.href,
                  })
                }}
                to='/pra-voce/cartoes/cotacao-dolar/'
                title={t('quick_access.dollar_quotation')}
              >
                {t('quick_access.dollar_quotation')}
              </Link>
            </li>
            <li className='list-inline-item' data-footer='tarifas'>
              <Link
                onClick={() => {
                  sendDatalayerFooterEvent({
                    section: '',
                    element_action: 'click button',
                    element_name: t('fares'),
                    redirect_url: `${window.location.origin}/tarifas/`,
                    c_page: window.location.href,
                  })
                }}
                to='/tarifas/' title={t('fares')}
              >{t('fares')}
              </Link>
            </li>
            <li className='list-inline-item' data-footer='seguranca'>
              <Link
                onClick={() => {
                  sendDatalayerFooterEvent({
                    section: '',
                    element_action: 'click button',
                    element_name: t('security'),
                    redirect_url: `${window.location.origin}/seguranca/`,
                    c_page: window.location.href,
                  })
                }}
                to='/seguranca/' title={t('security')}
              >{t('security')}
              </Link>
            </li>
            <li className='list-inline-item' data-footer='privacidade-de-dados'>
              <Link
                onClick={() => {
                  sendDatalayerFooterEvent({
                    section: '',
                    element_action: 'click button',
                    element_name: t('data_privacy'),
                    redirect_url: `${window.location.origin}/privacidade-de-dados/`,
                    c_page: window.location.href,
                  })
                }}
                to='/privacidade-de-dados/'
                title={t('data_privacy')}
              >{t('data_privacy')}
              </Link>
            </li>
            <li className='list-inline-item' data-footer='politica-de-privacidade'>
              <Link
                onClick={() => {
                  sendDatalayerFooterEvent({
                    section: '',
                    element_action: 'click button',
                    element_name: t('privacy_policy'),
                    redirect_url: `${window.location.origin}/politica-de-privacidade/`,
                    c_page: window.location.href,
                  })
                }}
                to='/politica-de-privacidade/'
                title={t('privacy_policy')}
              >{t('privacy_policy')}
              </Link>
            </li>
            <li className='list-inline-item' data-footer='canal-de-denuncia'>
              <a
                onClick={() => {
                  sendDatalayerFooterEvent({
                    section: '',
                    element_action: 'click button',
                    element_name: t('help_center.report_channel'),
                    redirect_url: 'https://www.canaldeetica.com.br/bancointer/',
                    c_page: window.location.href,
                  })
                }}
                href='https://www.canaldeetica.com.br/bancointer/'
                title={t('help_center.report_channel')}
              >{t('help_center.report_channel')}
              </a>
            </li>
            <li className='list-inline-item' data-footer='politica-de-seguranca-da-informacao'>
              <Link
                onClick={() => {
                  sendDatalayerFooterEvent({
                    section: '',
                    element_action: 'click button',
                    element_name: t('security_policy'),
                    redirect_url: `${window.location.origin}/politica-de-seguranca/`,
                    c_page: window.location.href,
                  })
                }}
                to='/politica-de-seguranca/'
                title={t('security_policy')}
              >{t('security_policy')}
              </Link>
            </li>
            <li className='list-inline-item' data-footer='politica-de-seguranca-da-informacao-para-fornecedores'>
              <Link
                onClick={() => {
                  sendDatalayerFooterEvent({
                    section: '',
                    element_action: 'click button',
                    element_name: t('security_policy_partners'),
                    redirect_url: `${window.location.origin}/politica-de-seguranca-para-fornecedores/`,
                    c_page: window.location.href,
                  })
                }}
                to='/politica-de-seguranca-para-fornecedores/'
                title={t('security_policy_partners')}
              >{t('security_policy_partners')}
              </Link>
            </li>
          </ul>
          <address>
            <div className='d-flex align-items-center mb-3'>
              &copy; <span className='fs-footer-10 ml-1 text-left' dangerouslySetInnerHTML={{ __html: t('cnpj') }} />
            </div>
            <div className='d-xl-flex'>
              <div className='d-flex mr-xl-3 mb-2 mb-xl-0'>
                <a
                  onClick={() => {
                    sendDatalayerFooterEvent({
                      section: '',
                      element_action: 'click button',
                      element_name: t('address_bh'),
                      redirect_url: 'https://goo.gl/maps/6ELbHe2AsaN6VzsE7',
                      c_page: window.location.href,
                    })
                  }}
                  href='https://goo.gl/maps/6ELbHe2AsaN6VzsE7'
                  className='address d-flex'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <OrangeIcon size='SM' icon='location' />
                  <span className='col fs-footer-10 pl-1 text-left' dangerouslySetInnerHTML={{ __html: t('address_bh') }} />
                </a>
              </div>
              <div className='d-flex mr-xl-3 mb-2 mb-xl-0'>
                <a
                  onClick={() => {
                    sendDatalayerFooterEvent({
                      section: '',
                      element_action: 'click button',
                      element_name: t('address_sp'),
                      redirect_url: 'https://goo.gl/maps/dhFZGQQuaAsU8fuy9',
                      c_page: window.location.href,
                    })
                  }}
                  href='https://goo.gl/maps/dhFZGQQuaAsU8fuy9'
                  className='address d-flex'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <OrangeIcon size='SM' icon='location' />
                  <span className='col fs-footer-10 pl-1 text-left' dangerouslySetInnerHTML={{ __html: t('address_sp') }} />
                </a>
              </div>
            </div>
          </address>
        </div>
      </div>
    </div>
  )
}

export default withTranslation('Footer')(FooterPT)
