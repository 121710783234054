import React, { ReactNode, memo } from 'react'

import * as InputStyles from '../Input/styles'
import { Loading } from '../Loading'
import * as S from './styles'

export type SelectNativeProps = {
  id?: string
  disabled?: boolean
  error?: boolean
  success?: boolean
  isLoading?: boolean
  helper?: ReactNode
  iconLeft?: ReactNode
  infoText?: string
  label?: string
  name?: string
  emptyOptionText?: string
  hideEmptyOption?: boolean
  value?: string | number
  options: Array<{ value: string | number; text: string }>
} & Omit<DefaultHTMLAttrs<HTMLSelectElement>, 'css'>

const Component = React.forwardRef(
  (
    {
      id,
      disabled = false,
      error = false,
      success = false,
      isLoading = false,
      helper,
      iconLeft,
      infoText,
      label,
      name,
      value,
      emptyOptionText = 'Selecione',
      hideEmptyOption = false,
      options,
      onChange,
      className,
      style,
      ...attrs
    }: SelectNativeProps,
    ref?: React.Ref<HTMLSelectElement>,
  ) => (
    <InputStyles.Container disabled={disabled} className={className} style={style}>
      <InputStyles.Title>
        <InputStyles.Label htmlFor={id} error={error} success={success} disabled={disabled}>
          {label}
        </InputStyles.Label>

        {helper && <span>{helper}</span>}
      </InputStyles.Title>

      <InputStyles.Box error={error} success={success} disabled={disabled}>
        {iconLeft && <InputStyles.IconLeft>{iconLeft}</InputStyles.IconLeft>}

        <S.Select
          id={id}
          name={name}
          disabled={disabled}
          hasIconRight
          hasIconLeft={Boolean(iconLeft)}
          value={value}
          onChange={(event) => onChange?.(event)}
          ref={ref}
          {...attrs}
        >
          {!hideEmptyOption && <option value="">{emptyOptionText}</option>}
          {options.map((option) => (
            <option value={option.value} key={option.value}>
              {option.text}
            </option>
          ))}
        </S.Select>

        <S.IconRight>
          {isLoading ? (
            <Loading color="var(--primary500)" />
          ) : (
            <S.ChevronMore disabled={disabled} />
          )}
        </S.IconRight>
      </InputStyles.Box>

      {infoText && (
        <InputStyles.InfoText error={error} success={success} disabled={disabled}>
          {infoText}
        </InputStyles.InfoText>
      )}
    </InputStyles.Container>
  ),
)

/**
 * - [`Inter UI Documentation`](https://inter-ui.bancointer.com.br/?path=/story/forms-selectnative-since-v1-0-1--simple)
 *
 * **Code example**
 * ```tsx
 * import React, { useState } from 'react'
 * import { SelectNative } from '@interco/inter-ui'
 *
 * export const MyPage = () => {
 *   const [value, setValue] = useState<string>('')
 *
 *
 *   const options = [
 *     {
 *       value: '1',
 *       text: 'First option',
 *     },
 *     {
 *       value: '2',
 *       text: 'Second option',
 *     },
 *   ]
 *
 *   return (
 *     <SelectNative
 *       label="Select"
 *       options={options}
 *       onChange={(event: React.ChangeEvent<HTMLSelectElement>) => setValue(event.target.value)}
 *       value={value}
 *     />
 *   )
 * }
 * ```
 */
export const SelectNative = memo(Component)
