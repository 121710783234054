import React, { useRef, useEffect, useCallback, memo } from 'react'

import ChevronUp from '@interco/icons/orangeds/LG/chevron-up'
import ChevronDown from '@interco/icons/orangeds/LG/chevron-down'
import { DEFAULT_ACCORDION_TRANSITION_DELAY } from '@/common/constants'

import * as S from './styles'
import { Text } from '../Text'
import { Spacing } from '../Spacing'

export const ChevronUpIcon = () => (
  <ChevronUp data-testid="icon-up" height={25} width={25} color="var(--primary500)" />
)

export const ChevronDownIcon = () => (
  <ChevronDown data-testid="icon-down" height={25} width={25} color="var(--primary500)" />
)

export type VariantProps = 'default' | 'containerized'

type CommonProps = {
  expanded: boolean
  onClick: () => void
  variant?: VariantProps
}

type DefaultAccordionProps = {
  labelComponent?: never
  title: string
}

type CustomAccordionProps = {
  labelComponent: React.ReactNode
  title?: never
  backgroundColor?: string
}

export type AccordionProps = (Omit<DefaultHTMLAttrs, 'onChange'> & CommonProps) &
  (DefaultAccordionProps | CustomAccordionProps)

export const Component = ({
  children,
  expanded = false,
  title,
  onClick,
  labelComponent,
  variant = 'default',
  ...attrs
}: PropsWithRequiredChildren<AccordionProps>) => {
  const detailsRef = useRef<HTMLDivElement>(null)
  const timeout = useRef<Timeout>()

  const handleClick = useCallback(() => {
    timeout.current && clearTimeout(timeout.current)
    onClick()
  }, [onClick])

  const changeDetailsMaxHeight = useCallback(
    (detailsElement: HTMLDivElement, maxHeight: string, delay?: number) => {
      const { style } = detailsElement
      style.maxHeight = `${detailsElement.scrollHeight}px`
      return setTimeout(() => {
        style.maxHeight = maxHeight
      }, delay)
    },
    [],
  )

  useEffect(() => {
    const detailsElement = detailsRef.current as HTMLDivElement

    if (expanded) {
      timeout.current = changeDetailsMaxHeight(
        detailsElement,
        'inherit',
        DEFAULT_ACCORDION_TRANSITION_DELAY,
      )
    } else if (detailsElement.style.maxHeight !== '') {
      timeout.current = changeDetailsMaxHeight(detailsElement, '0px', 50)
    }
  }, [changeDetailsMaxHeight, expanded])

  return (
    <S.Container variant={variant} {...attrs}>
      {title && (
        <S.Summary onClick={handleClick} role="button">
          <Text variant="body-3" bold colorWeight={500}>
            {title}
          </Text>
          <Spacing ml="xs">{expanded ? <ChevronUpIcon /> : <ChevronDownIcon />}</Spacing>
        </S.Summary>
      )}

      {labelComponent && (
        <S.CustomLabelContainer onClick={handleClick} role="button">
          {labelComponent}
        </S.CustomLabelContainer>
      )}

      <S.Details ref={detailsRef} role="region">
        <S.DetailPanel variant={variant}>{children}</S.DetailPanel>
      </S.Details>
    </S.Container>
  )
}

/**
 * - [`Inter UI Documentation`](https://inter-ui.bancointer.com.br/?path=/story/pages-utils-accordion-since-v1-0-1--simple)
 * - [`Figma Orange DS Documentation`](https://www.figma.com/file/7QVC2u96bJmyW8qQ8mBNb3/DS-%2F-Components-%2F-Global?node-id=8555%3A3467&t=BZjTU2fzcQKIUqZO-0)
 *
 * **Code example**
 * ```tsx
 * import React, { useState } from 'react'
 *
 * import { Accordion } from '@interco/inter-ui/components/Accordion'
 *
 * export const MyPage = () => {
 *   const [expanded, setExpanded] = useState<boolean>(false)
 *
 *   return (
 *     <Accordion
 *       title="Accordion Title"
 *       expanded={expanded}
 *       onClick={() => setExpanded(!expanded)}
 *     >
 *       <Text variant="body-3">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</Text>
 *       </Accordion>
 *   )
 * }
 * ```
 */
export const Accordion = memo(Component)
